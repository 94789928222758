import AceOfCups from "../../../assets/riderWaitTarot/riderWaite_c00_AceOfCups-min.jpg"
import TwoOfCups from "../../../assets/riderWaitTarot/riderWaite_c01_TwoOfCups-min.jpg"
import ThreeOfCups from "../../../assets/riderWaitTarot/riderWaite_c02_ThreeOfCups-min.jpg"
import FourOfCups from "../../../assets/riderWaitTarot/riderWaite_c03_FourOfCups-min.jpg"
import FiveOfCups from "../../../assets/riderWaitTarot/riderWaite_c04_FiveOfCups-min.jpg"
import SixOfCups from "../../../assets/riderWaitTarot/riderWaite_c05_SixOfCups-min.jpg"
import SevenOfCups from "../../../assets/riderWaitTarot/riderWaite_c06_SevenOfCups-min.jpg"
import EightOfCups from "../../../assets/riderWaitTarot/riderWaite_c07_EightOfCups-min.jpg"
import NineOfCups from "../../../assets/riderWaitTarot/riderWaite_c08_NineOfCups-min.jpg"
import TenOfCups from "../../../assets/riderWaitTarot/riderWaite_c09_TenOfCups-min.jpg"
import PageOfCups from "../../../assets/riderWaitTarot/riderWaite_c10_PageOfCups-min.jpg"
import KnightOfCups from "../../../assets/riderWaitTarot/riderWaite_c11_KnightOfCups-min.jpg"
import QueenOfCups from "../../../assets/riderWaitTarot/riderWaite_c12_QueenOfCups-min.jpg"
import KingOfCups from "../../../assets/riderWaitTarot/riderWaite_c13_KingOfCupsjpg-min.jpg"
import Fool from "../../../assets/riderWaitTarot/riderWaite_m00_Fool-min.jpg"
import Magician from "../../../assets/riderWaitTarot/riderWaite_m01_Magician-min.jpg"
import HighPriestess from "../../../assets/riderWaitTarot/riderWaite_m02_HighPriestess-min.jpg"
import Empress from "../../../assets/riderWaitTarot/riderWaite_m03_Empress-min.jpg"
import Emperor from "../../../assets/riderWaitTarot/riderWaite_m04_Emperor-min.jpg"
import Hierophant from "../../../assets/riderWaitTarot/riderWaite_m05_Hierophant-min.jpg"
import Lovers from "../../../assets/riderWaitTarot/riderWaite_m06_Lovers-min.jpg"
import Chariot from "../../../assets/riderWaitTarot/riderWaite_m07_Chariot-min.jpg"
import Strength from "../../../assets/riderWaitTarot/riderWaite_m08_Strength-min.jpg"
import Hermit from "../../../assets/riderWaitTarot/riderWaite_m09_Hermit-min.jpg"
import WheelOfFortune from "../../../assets/riderWaitTarot/riderWaite_m10_WheelOfFortune-min.jpg"
import Justice from "../../../assets/riderWaitTarot/riderWaite_m11_Justice-min.jpg"
import HangedMan from "../../../assets/riderWaitTarot/riderWaite_m12_HangedMan-min.jpg"
import Death from "../../../assets/riderWaitTarot/riderWaite_m13_Death-min.jpg"
import Temperance from "../../../assets/riderWaitTarot/riderWaite_m14_Temperance-min.jpg"
import Devil from "../../../assets/riderWaitTarot/riderWaite_m15_Devil-min.jpg"
import Tower from "../../../assets/riderWaitTarot/riderWaite_m16_Tower-min.jpg"
import Star from "../../../assets/riderWaitTarot/riderWaite_m17_Star-min.jpg"
import Moon from "../../../assets/riderWaitTarot/riderWaite_m18_Moon-min.jpg"
import Sun from "../../../assets/riderWaitTarot/riderWaite_m19_Sunjpg-min.jpg"
import Judgement from "../../../assets/riderWaitTarot/riderWaite_m20_Judgement-min.jpg"
import World from "../../../assets/riderWaitTarot/riderWaite_m21_World-min.jpg"
import AceOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p00_AceOfPentacles-min.jpg"
import TwoOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p01_TwoOfPentacles-min.jpg"
import ThreeOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p02_ThreeOfPentacles-min.jpg"
import FourOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p03_FourOfPentacles-min.jpg"
import FiveOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p04_FiveOfPentacles-min.jpg"
import SixOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p05_SixOfPentacles-min.jpg"
import SevenOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p06_SevenOfPentacles-min.jpg"
import EightOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p07_EightOfPentacles-min.jpg"
import NineOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p08_NineOfPentacles-min.jpg"
import TenOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p09_TenOfPentacles-min.jpg"
import PageOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p10_PageOfPentacles-min.jpg"
import KnightOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p11_KnightOfPentacles-min.jpg"
import QueenOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p12_QueenOfPentacles-min.jpg"
import KingOfPentacles from "../../../assets/riderWaitTarot/riderWaite_p13_KingOfPentacles-min.jpg"
import AceOfSwords from "../../../assets/riderWaitTarot/riderWaite_s00_AceOfSwords-min.jpg"
import TwoOfSwords from "../../../assets/riderWaitTarot/riderWaite_s01_TwoOfSwords-min.jpg"
import ThreeOfSwords from "../../../assets/riderWaitTarot/riderWaite_s02_ThreeOfSwords-min.jpg"
import FourOfSwords from "../../../assets/riderWaitTarot/riderWaite_s03_FourOfSwords-min.jpg"
import FiveOfSwords from "../../../assets/riderWaitTarot/riderWaite_s04_FiveOfSwords-min.jpg"
import SixOfSwords from "../../../assets/riderWaitTarot/riderWaite_s05_SixOfSwords-min.jpg"
import SevenOfSwords from "../../../assets/riderWaitTarot/riderWaite_s06_SevenOfSwords-min.jpg"
import EightOfSwords from "../../../assets/riderWaitTarot/riderWaite_s07_EightOfSwords-min.jpg"
import NineOfSwords from "../../../assets/riderWaitTarot/riderWaite_s08_NineOfSwords-min.jpg"
import TenOfSwords from "../../../assets/riderWaitTarot/riderWaite_s09_TenOfSwords-min.jpg"
import PageOfSwords from "../../../assets/riderWaitTarot/riderWaite_s10_PageOfSwords-min.jpg"
import KnightOfSwords from "../../../assets/riderWaitTarot/riderWaite_s11_KnightOfSwords-min.jpg"
import QueenOfSwords from "../../../assets/riderWaitTarot/riderWaite_s12_QueenOfSwords-min.jpg"
import KingOfSwords from "../../../assets/riderWaitTarot/riderWaite_s13_KingOfSwords-min.jpg"
import AceOfWands from "../../../assets/riderWaitTarot/riderWaite_w00_AceOfWands-min.jpg"
import TwoOfWands from "../../../assets/riderWaitTarot/riderWaite_w01_TwoOfWands-min.jpg"
import ThreeOfWands from "../../../assets/riderWaitTarot/riderWaite_w02_ThreeOfWands-min.jpg"
import FourOfWands from "../../../assets/riderWaitTarot/riderWaite_w03_FourOfWands-min.jpg"
import FiveOfWands from "../../../assets/riderWaitTarot/riderWaite_w04_FiveOfWands-min.jpg"
import SixOfWands from "../../../assets/riderWaitTarot/riderWaite_w05_SixOfWands-min.jpg"
import SevenOfWands from "../../../assets/riderWaitTarot/riderWaite_w06_SevenOfWands-min.jpg"
import EightOfWands from "../../../assets/riderWaitTarot/riderWaite_w07_EightOfWands-min.jpg"
import NineOfWands from "../../../assets/riderWaitTarot/riderWaite_w08_NineOfWands-min.jpg"
import TenOfWands from "../../../assets/riderWaitTarot/riderWaite_w09_TenOfWands-min.jpg"
import PageOfWands from "../../../assets/riderWaitTarot/riderWaite_w10_PageOfWands-min.jpg"
import KnightOfWands from "../../../assets/riderWaitTarot/riderWaite_w11_KnightOfWands-min.jpg"
import QueenOfWands from "../../../assets/riderWaitTarot/riderWaite_w12_QueenOfWands-min.jpg"
import KingOfWands from "../../../assets/riderWaitTarot/riderWaite_w13_KingOfWands-min.jpg"

const images = [
    {
        cups: [
            {
                id: 1,
                image: AceOfCups,
                text: "Ace Of Cups"
            },
            {
                id: 2,
                image: TwoOfCups,
                text: "Two Of Cups"
            },
            {
                id: 3,
                image: ThreeOfCups,
                text: "Three Of Cups"
            },
            {
                id: 4,
                image: FourOfCups,
                text: "Four Of Cups"
            },
            {
                id: 5,
                image: FiveOfCups,
                text: "Five Of Cups"
            },
            {
                id: 6,
                image: SixOfCups,
                text: "Six Of Cups"
            },
            {
                id: 7,
                image: SevenOfCups,
                text: "Seven Of Cups"
            },
            {
                id: 8,
                image: EightOfCups,
                text: "Eight Of Cups"
            },
            {
                id: 9,
                image: NineOfCups,
                text: "Nine Of Cups"
            },
            {
                id: 10,
                image: TenOfCups,
                text: "Ten Of Cups"
            },
            {
                id: 11,
                image: PageOfCups,
                text: "Page Of Cups"
            },
            {
                id: 12,
                image: KnightOfCups,
                text: "Knight Of Cups"
            },
            {
                id: 13,
                image: QueenOfCups,
                text: "Queen Of Cups"
            },
            {
                id: 14,
                image: KingOfCups,
                text: "King Of Cups"
            },
        ],
        arcana: [
            {
                id: 1,
                image: Fool,
                text: "Fool"
            },
            {
                id: 2,
                image: Magician,
                text: "The Magician"
            },
            {
                id: 3,
                image: HighPriestess,
                text: "The HighPriestess"
            },
            {
                id: 4,
                image: Empress,
                text: "The Empress"
            },
            {
                id: 5,
                image: Emperor,
                text: "The Emperor"
            },
            {
                id: 6,
                image: Hierophant,
                text: "The Hierophant"
            },
            {
                id: 7,
                image: Lovers,
                text: "The Lovers"
            },
            {
                id: 8,
                image: Chariot,
                text: "The Chariot"
            },
            {
                id: 9,
                image: Strength,
                text: "Strength"
            },
            {
                id: 10,
                image: Hermit,
                text: "The Hermit"
            },
            {
                id: 11,
                image: WheelOfFortune,
                text: "WheelOfFortune"
            },
            {
                id: 12,
                image: Justice,
                text: "Justice"
            },
            {
                id: 13,
                image: HangedMan,
                text: "The HangedMan"
            },
            {
                id: 14,
                image: Death,
                text: "Death"
            },
            {
                id: 15,
                image: Temperance,
                text: "Temperance"
            },
            {
                id: 16,
                image: Devil,
                text: "The Devil"
            },
            {
                id: 17,
                image: Tower,
                text: "The Tower"
            },
            {
                id: 18,
                image: Star,
                text: "The Star"
            },
            {
                id: 19,
                image: Moon,
                text: "The Moon"
            },
            {
                id: 20,
                image: Sun,
                text: "The Sun"
            },
            {
                id: 21,
                image: Judgement,
                text: "Judgement"
            },
            {
                id: 22,
                image: World,
                text: "The World"
            },
        ],
        pentacles: [
            {
                id: 1,
                image: AceOfPentacles,
                text: "Ace Of Pentacles"
            },
            {
                id: 2,
                image: TwoOfPentacles,
                text: "Two Of Pentacles"
            },
            {
                id: 3,
                image: ThreeOfPentacles,
                text: "Three Of Pentacles"
            },
            {
                id: 4,
                image: FourOfPentacles,
                text: "Four OfPentacles"
            },
            {
                id: 5,
                image: FiveOfPentacles,
                text: "Five Of Pentacles"
            },
            {
                id: 6,
                image: SixOfPentacles,
                text: "Six Of Pentacles"
            },
            {
                id: 7,
                image: SevenOfPentacles,
                text: "Seven Of Pentacles"
            },
            {
                id: 8,
                image: EightOfPentacles,
                text: "Eight Of Pentacles"
            },
            {
                id: 9,
                image: NineOfPentacles,
                text: "Nine Of Pentacles"
            },
            {
                id: 10,
                image: TenOfPentacles,
                text: "Ten Of Pentacles"
            },
            {
                id: 11,
                image: PageOfPentacles,
                text: "Page Of Pentacles"
            },
            {
                id: 12,
                image: KnightOfPentacles,
                text: "Knight Of Pentacles"
            },
            {
                id: 13,
                image: QueenOfPentacles,
                text: "Queen Of Pentacles"
            },
            {
                id: 14,
                image: KingOfPentacles,
                text: "King Of Pentacles"
            },
        ],
        swords: [
            {
                id: 1,
                image: AceOfSwords,
                text: "Ace Of Swords"
            },
            {
                id: 2,
                image: TwoOfSwords,
                text: "Two Of Swords"
            },
            {
                id: 3,
                image: ThreeOfSwords,
                text: "Three Of Swords"
            },
            {
                id: 4,
                image: FourOfSwords,
                text: "Four Of Swords"
            },
            {
                id: 5,
                image: FiveOfSwords,
                text: "Five Of Swords"
            },
            {
                id: 6,
                image: SixOfSwords,
                text: "Six Of Swords"
            },
            {
                id: 7,
                image: SevenOfSwords,
                text: "Seven Of Swords"
            },
            {
                id: 8,
                image: EightOfSwords,
                text: "Eight Of Swords"
            },
            {
                id: 9,
                image: NineOfSwords,
                text: "Nine Of Swords"
            },
            {
                id: 10,
                image: TenOfSwords,
                text: "Ten Of Swords"
            },
            {
                id: 11,
                image: PageOfSwords,
                text: "Page Of Swords"
            },
            {
                id: 12,
                image: KnightOfSwords,
                text: "Knight Of Swords"
            },
            {
                id: 13,
                image: QueenOfSwords,
                text: "Queen Of Swords"
            },
            {
                id: 14,
                image: KingOfSwords,
                text: "King Of Swords"
            },
        ],
        wands: [
            {
                id: 1,
                image: AceOfWands,
                text: "Ace Of Wands"
            },
            {
                id: 2,
                image: TwoOfWands,
                text: "Two Of Wands"
            },
            {
                id: 3,
                image: ThreeOfWands,
                text: "Three Of Wands"
            },
            {
                id: 4,
                image: FourOfWands,
                text: "Four Of Wands"
            },
            {
                id: 5,
                image: FiveOfWands,
                text: "Five Of Wands"
            },
            {
                id: 6,
                image: SixOfWands,
                text: "Six Of Wands"
            },
            {
                id: 7,
                image: SevenOfWands,
                text: "Seven Of Wands"
            },
            {
                id: 8,
                image: EightOfWands,
                text: "Eight Of Wands"
            },
            {
                id: 9,
                image: NineOfWands,
                text: "Nine Of Wands"
            },
            {
                id: 10,
                image: TenOfWands,
                text: "Ten Of Wands"
            },
            {
                id: 11,
                image: PageOfWands,
                text: "Page Of Wands"
            },
            {
                id: 12,
                image: KnightOfWands,
                text: "Knight Of Wands"
            },
            {
                id: 13,
                image: QueenOfWands,
                text: "Queen Of Wands"
            },
            {
                id: 14,
                image: KingOfWands,
                text: "King Of Wands"
            }
        ]
    }
]

export default images